body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
}

.splash-row-main {
  padding-top: 20%;
}
.menu {
  padding: 20px 0;
  min-height: 100vh;
  position: relative;
}

.bg {
  min-height: 80vh;
  margin-top: 20vh;
  width: 100%;
  background-size: contain;
  background-repeat: repeat-y;
  position: fixed;
  top: 0;
  z-index: -1
}

.left-bg {
  background-image: url('https://swad.nyc3.cdn.digitaloceanspaces.com/patelsfreshkitchen/left-bg.png');
  background-position: 0%;
}

.right-bg {
  background-image: url('https://swad.nyc3.cdn.digitaloceanspaces.com/patelsfreshkitchen/right-bg.png');
  background-position: 100%;
}

.menu-logo {
  width: 60%;
}

.menu-section {
  padding: 50px 0 20px 0;
}

.bakery-section {
  padding: 20px 0;
}

.category-name {
  color: #EF6C38;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: underline;
}

.category-price {
  color: black;
  padding-left: 10px;
}

.product-name {
  font-weight: 600;
  font-size: 20px;
}

.product-price {
  font-weight: 600;
  font-size: 20px;
}

.orange-dot {
  color: #EF6C38;
  padding: 0 8px 0 4px;
}

.details {
  padding: 50px 0 20px 0;
}

.category-detail-name {
  color: black;
  text-decoration: underline;
  text-transform: uppercase;
}

.category-detail-price {
  font-size: 20px;
}

.category-product-detail-name {
  color: #EF6C38;
  display: inline;
  font-weight: bold;
  padding: 0 20px;
  cursor: pointer;
}

.active {
  color: black !important;
}

.product-detail-section {
  padding-top: 40px;
  text-align: left;
}

.product-detail-section h3 {
  padding: 10px 0;
  font-weight: bold;
}

.product-detail-section h4 {
  padding: 0 0 5px 0;
  font-weight: bold;
}

.product-detail-nutrition {
  width: 100%;
  max-height: 600px;
  object-fit: contain;
}

.close-details {
  padding-top: 50px;
  color: #EF6C38;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.instructions {
  text-align: center;
  color: #00A85A;
  font-weight: bold;
}

.hidden {
  display: none;
}

.qr-btn {
  border-bottom-right-radius: 10px;
  padding: 18px;
  background-color: #00A85A;
  border: none;
  position: absolute;
  top: 0px;
  left: 0px;
}

.large-qr-btn {
  border-bottom-right-radius: 10px;
  padding: 18px;
  background-color: #00A85A;
  border: none;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn-primary{
  border-radius: 25px;
  margin-top: 12px;
  font-weight: 400;
  padding: 11px 45px;
  background-color: #00A85A;
  border: none;
}

.btn-primary:hover {
  background-color: #00A85A;
}

.splash-page {
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.basic-single {
  width: 40%;
}

.splash-card {
  background-color: transparent;
  /* background-color: rgba(255,255,255, 0.8); */
  border: none;
  border-radius: 10px;
}

.splash-img {
  width: 40%;
}

.product-menu-section {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  width: 70%;
}

.product-menu-section > * {
  padding: 0;
  margin: 0;
}

.spacing-span {
  flex: 1;
  overflow: hidden;
}

@media (max-width: 2000px) {
  .col-xl-12 {
    flex: 0 0 auto !important;
    width: 100% !important;
  }

  .menu-section {
    padding: 50px 0 20px 17%;
  }

}

@media (max-width: 1260px) {
  .row {
    margin: 0px;
  }
  .bg {
    width: 20%;
    background-position: top;
  }
  .right-bg {
    right: 0;
  }
}
@media (max-width: 1198px) {
  .product-menu-section {
    width: 80%;
  }
}


@media (max-width: 800px) {
  .menu-logo {
    width: 80%;
  }
  .bakery-section {
    padding: 20px 75px 20px 0;
  }

  .category-name {
    font-size: 28px;
  }

  .category-price {
    font-size: 24px;
  }

  .product-name {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .bakery-section {
    padding: 10px 60px 10px 0;
  }

  .product-menu-section {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .bakery-section {
    padding: 10px 60px;
  }

  .category-name {
    font-size: 20px;
  }

  .category-price {
    font-size: 18px;
  }

  .category-product-detail-name {
    font-size: 12px;
    padding: 0 3px;
  }

  .category-product-detail-name::after {
    content: ' |';
  }
}

@media (max-width: 520px) {
  .basic-single {
    width: 100%;
  }

  .splash-row-main {
    padding-top: 42vh;
  }
}

@media (max-width: 414px) {
  .menu-section {
    padding: 50px 0 20px 5%;
  }

}
